section.faqs-page-section .product-accordion {
  width: 75%;
  margin: 0 auto;
  padding: 32px;
  background: #f8f8f8;
}

.faqs-page-section h2 {
  padding: 0 0 70px;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #1179bf;
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .faqs-page-section h2 {
    padding: 0 24px 40px;
  }
}

section.faqs-page-section {
  position: relative;
}

@media (max-width: 767px) {
  section.faqs-page-section {
    border-radius: 16px;
    background: #f8f8f8;
  }

  .faqs-page-section .container {
    padding: 0;
  }

  section.faqs-page-section .product-accordion {
    width: 100%;
  }
}

.maincontent {
  background-color: #fff;
  width: 100%;
  color: #fff;
}

.maincontent h1 {
  margin: 0% auto;
  width: 70%;
  font-size: 3.8rem;
  margin-bottom: 1rem;
  font-weight: 300;
}
.maincontent h1 span {
  font-weight: 700;
}

.maincontent hr {
  height: 1px;
  border-top: 1px solid #ccc;
  background: #177dc1c5;
  margin: 0% auto;
  width: 70%;
  margin-bottom: 20px;
}

.maincontent h3 {
  margin: 0% auto;
  width: 70%;
  font-size: 26px;
  margin-bottom: 26px;
  font-weight: 700;
}

.maincontent .text p,
.faqs-page-section .text p {
  text-align: justify;
  margin: 0% auto;
  width: 100%;
  font-size: 16px;
  line-height: 21px;
}

.maincontent .text p:not(:last-child),
.faqs-page-section .text p:not(:last-child) {
  margin-bottom: 20px;
}

.maincontent p:last-child {
  margin-bottom: 0px;
}

/* Component Needs */
.product-accordion > input,
.product-accordion section > div > .text {
  display: none;
  opacity: 1;
}
#tab1:checked ~ section .tab1 .text,
#tab2:checked ~ section .tab2 .text,
#tab3:checked ~ section .tab3 .text,
#tab4:checked ~ section .tab4 .text,
#tab5:checked ~ section .tab5 .text,
#tab6:checked ~ section .tab6 .text,
#tab7:checked ~ section .tab7 .text,
#tab8:checked ~ section .tab8 .text,
#tab9:checked ~ section .tab9 .text,
#tab10:checked ~ section .tab10 .text,
#tab11:checked ~ section .tab11 .text,
#tab12:checked ~ section .tab12 .text,
#tab13:checked ~ section .tab13 .text,
#tab14:checked ~ section .tab14 .text,
#tab15:checked ~ section .tab15 .text,
#tab16:checked ~ section .tab16 .text,
#tab17:checked ~ section .tab17 .text,
#tab18:checked ~ section .tab18 .text,
#tab19:checked ~ section .tab19 .text,
#tab20:checked ~ section .tab20 .text,
#tab21:checked ~ section .tab21 .text,
#tab22:checked ~ section .tab22 .text {
  display: block;
  border: 1px solid #fff;
}
/* Visual Styles */
.product-accordion {
  background-color: var(--color-grey-4);
  border-radius: 16px;
  animation: fadeIn 0.7s;
}

.product-accordion section {
  list-style: none;
  margin: 0;
  padding: 0;
}

.product-accordion section > div {
  border-radius: 16px !important;
  background-color: var(--color-white);
}

.product-accordion section > div label {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.75;
  padding: 3rem 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  color: var(--color-grey-1);
  cursor: pointer;
  transition: all 0.4s;
}

/* .product-accordion section > div.active label {
  padding: 45px 45px 35px 55px;
} */

.product-accordion section > div.active p {
  padding-top: 35px;
  border-top: 2px solid var(--color-grey-3);
}

.product-accordion section > div label span:first-of-type {
  width: 90%;
}
.product-accordion section > div:not(:first-of-type) {
  margin-top: 0.5rem;
}

.product-accordion section > div label .faq-toggle-icon {
  display: inline-block;
  background: url(../images/general/faq/down.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  vertical-align: middle;
  width: 2.125rem;
  height: 2.125rem;
  flex-shrink: 0;
}

.product-accordion section > div div {
  padding: 0 35px 35px 45px;
  width: 100%;
  color: #6b7786;
  -webkit-animation: fadeIn 0.7s;
  animation: fadeIn 0.7s;
}

.product-accordion section > div.active label .faq-toggle-icon {
  background-image: url(../images/general/faq/up.svg);
}

.product-accordion section > div label span.arrow {
  filter: brightness(0) invert(1);
  transform: rotate(-90deg);
  margin-right: 10px;
  width: 15px;
  height: 15px;
}

.glossary-section p.no-margin {
  margin: 0;
}

@media (max-width: 1200px) {
  label .arrow {
    display: inline-block;
  }
}

@media (max-width: 600px) {
  .product-accordion {
    width: 100%;
    margin: 0 auto;
  }
  .product-accordion section > div:first-of-type,
  .product-accordion section > div:first-of-type:hover,
  .product-accordion section > div:last-of-type,
  .product-accordion section > div:last-of-type:hover {
    border-radius: 0;
  }

  .product-accordion section > div label {
    font-size: 1.3rem;
  }

  .product-accordion section > div label span.arrow {
    width: 12px;
    height: 12px;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* NEW */

.js .accordion__panel {
  display: none;
}

.js .accordion__panel--expanded {
  display: block;
}

.accordion {
  max-width: 1085px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #f8f8f8;
  padding: 32px 32px 32px 24px;
  box-sizing: border-box;
  border-radius: 16px;
}

.accordion__inner {
  border-radius: 16px;
  transition: height 0.2s ease-in-out;
}

.accordion__title {
  border-radius: 16px 16px 0 0;
}

.accordion__panel {
  border-radius: 0 0 16px 16px;
}

.js .accordion__title {
  border-radius: 16px;
}

.js .accordion__title--expanded {
  border-radius: 16px 16px 0 0;
}

.accordion__title {
  padding: 0;
  overflow: hidden;
}

.accordion__title,
.accordion__panel {
  background-color: #ffffff;
}

.accordion__toggle {
  display: block;
  width: 100%;
  border: 0;
  padding: 37px;
  color: #3f3f3f;
  background-color: #ffffff;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
}

@media (max-width: 767px) {
  .accordion__toggle {
    font-size: 14px;
    line-height: 17px;
  }
}

.accordion__toggle:disabled {
  background-color: #ffffff;
}

.accordion__toggle span {
  display: block;
}

.accordion__panel {
  margin-bottom: 8px;
  padding: 0 37px 37px;
}

.accordion__panel:nth-last-child(2) {
  margin-bottom: 0;
}

.accordion__title--indicator span {
  display: block;
}

.accordion__title--indicator span {
  float: left;
}

.accordion__title--indicator .accordion__toggle {
  position: relative;
  min-height: 72px;
}

.accordion__title--indicator .accordion__indicator {
  position: absolute;
  top: 8px;
  right: 8px;
}

.accordion__title--indicator .accordion__toggle:before,
.accordion__title--indicator .accordion__toggle:after {
  content: '';
  display: table;
}

.accordion__title--indicator .accordion__toggle:after {
  clear: both;
}

@supports (display: flex) {
  .accordion__title--indicator .accordion__toggle::before,
  .accordion__title--indicator .accordion__toggle::after {
    content: none;
  }

  .accordion__title--indicator .accordion__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }

  .accordion__title--indicator span {
    float: none;
  }

  .accordion__title--indicator .accordion__indicator {
    position: static;
  }
}

.accordion__indicator {
  flex-shrink: 0;
  margin: 8px;
  width: 33px;
  height: 33px;
  background-image: url(../images/general/faq/down.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.animation-supported
  .accordion
  .accordion__toggle--expanded
  .accordion__indicator {
  transform: rotate(180deg);
}

.animation-supported
  .accordion.animated
  .accordion__toggle
  .accordion__indicator {
  transition: transform 0.2s ease-in-out;
}

.animation-supported.js .accordion__panel,
.animation-supported.js .accordion__panel--expanded {
  display: block;
}

.animation-supported.js .accordion__panel {
  padding-top: 0;
  padding-bottom: 0;
}

.animation-supported.js .accordion__panel--expanded {
  padding-top: 0;
  padding-bottom: 37px;
}

.animation-supported.js .accordion .accordion__panel {
  overflow: hidden;
  height: 0;
}

.animation-supported.js .accordion .accordion__panel--expanded {
  height: auto;
}

.animation-supported.js .accordion__inner .accordion__title {
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.animation-supported.js .accordion.animated .accordion__panel {
  overflow: hidden;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  transition: 0.2s ease-in-out height;
}

@keyframes openPanel {
  from {
    padding-top: 0;
    padding-bottom: 0;
  }

  to {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

@keyframes closeTitle {
  from {
    border-radius: 16px 16px 0 0;
  }

  to {
    border-radius: 16px 16px 16px 16px;
  }
}

@keyframes closePanel {
  from {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  to {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* Change span paddings */

.accordion__toggle span {
  padding: 8px;
}
.js .accordion__toggle {
  cursor: pointer;
}

.accordion__toggle {
  border: 0;
  background-color: #ffffff;
}

.js .accordion__toggle:hover,
.js .hover .accordion__toggle,
.js .accordion__toggle:active,
.js .active .accordion__toggle {
  background-color: #ffffff;
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible#selectively_showing_the_focus_indicator */

.js .accordion__toggle:focus,
.js .focus .accordion__toggle {
  /* Provide a fallback style for browsers
  that don't support :focus-visible */
  box-shadow: 0 0 0 2px #ffffff inset, 0 0 0 3px #c0c0c0 inset;
}

.js .accordion__toggle:focus:not(:focus-visible) {
  /* Remove the focus indicator on mouse-focus for browsers
  that do support :focus-visible */
  box-shadow: none;
  outline: none;
}

.js .accordion__toggle:focus-visible {
  /* Draw focus style for
  keyboard-focus on browsers that do support
  :focus-visible */
  box-shadow: 0 0 0 2px #ffffff inset, 0 0 0 3px var(--color-grey-3) inset;
  outline: none;
}

.disabled .accordion__toggle:hover,
.disabled .accordion__toggle:active {
  cursor: default;
  background-color: #ffffff;
}

.accordion__content img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.accordion__content {
  color: #6b7786;
}

.accordion__content p {
  padding: 18px 0;
  border-top: 2px solid var(--color-grey-3);
  font-size: 16px;
  line-height: 21px;
}

.accordion__show-more {
  display: flex;
  justify-content: center;
  padding-top: 32px;
}

.accordion__show-more .call-action {
  background-color: #f9ae3b;
  cursor: pointer;
  display: inline-block;
  min-width: 230px;
  width: auto;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  padding: 19px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  margin-top: 0;
}

@media (max-width: 767px) {
  .accordion__show-more .call-action {
    font-size: 16px;
    line-height: 20px;
    padding: 14px 18px;
    width: 100%;
    max-width: 288px;
    min-width: auto;
  }
}
